<template>
  <router-view/>
</template>


<script setup>


</script>

<style>
html, body, #app {
  margin: 0;
  padding: 0;
  /*height: 100%;*/
}
</style>
