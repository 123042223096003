import {ofetch} from "ofetch";
// import {useAuthStore} from "@/stores/useAuthStore";
import {useRouter} from "vue-router/dist/vue-router";
import {useRoute} from "vue-router";

export const useFetch = () => {

  // const authStore = useAuthStore()
  const route = useRoute()
  const router = useRouter()
  // const baseURL = 'http://localhost:1337'
  // const baseURL = 'http://roomdesigner.katskov.tech:1337'
  // const baseURL = 'http://watmanpro.ru'
  const baseURL = 'https://watmanpro.ru'
  const apiURL = `${baseURL}`
  const __request = async (url, options) => {
    // const onResponseError = async ({request, response, options}) => {
    //   console.log('ON REQUEST: ', request, options)
    //   if (response.status === 401) {
    //     await router.replace(`/admin/login?next=${window.location.href}`)
    //   }
    // }
    return await ofetch(url, {baseURL: apiURL, ...options},);

  }

  const request = async (url, options) => {
    return await __request(url, options)
  }

  // const authRequest = async (url, options) => {
  //   const headers = {
  //     'Authorization': `Bearer ${authStore.getToken()}`
  //   }
  //   return await __request(url, {headers, ...options})
  //   // return await ofetch(url, {baseURL, headers, ...options}, )
  // }

  return {
    request,
    baseURL,
    // authRequest,
  }
}