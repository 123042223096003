<template>
  <div class="flex  h-full justify-center items-center ">
    <a-card class="md:w-[400px]" title="Вход" >
      <a-alert
        v-if="error"
        class="mb-2"
        message="Неправильный код доступа"
        type="error"
      />
      <a-input-password  v-model:value="passcode" placeholder="Введи код доступа" />
      <a-button class="mt-3" block type="primary" @click="login">Ввод</a-button>

    </a-card>
  </div>
</template>

<script setup>

import {onBeforeMount, ref} from 'vue'
import {useRouter} from "vue-router";
const router = useRouter()

const passcode = ref(null)

const CODE = '69SMi3WLjP88'
const error = ref(false)

onBeforeMount(() => {
  const code = localStorage.getItem('passcode')
  if (code == CODE){
    router.push('/')
  }
})

const login = () => {
  if (passcode.value != CODE){
    error.value = true
  }
  else {
    localStorage.setItem('passcode', CODE)
    router.push('/')
  }
}


</script>

<style scoped>

</style>