<template>
  <div class="block md:hidden z-50 bg-white w-screen flex justify-center items-center p-[20%]">
    <!-- Заглушка для мобильных устройств -->
    <h1 class="text-center text-lg font-bold">Сайт не доступен на мобильных устройствах. <br>Зайдите с компьютера</h1>
  </div>

  <div class="hidden md:flex p-5  flex-col h-full box-border gap-[20px]">
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="Room Designer"
    >
      <template #subTitle>
        <div class="flex gap-[10px]">
          <a-button v-for="r in rooms" :key="r.id" :type="room === r.id ? 'primary' : undefined" @click="room = r.id">{{ r.attributes.name }}</a-button>
        </div>

      </template>
      <template #extra>
        <a-button type="primary" @click="exportToZip">Сохранить</a-button>

      </template>
    </a-page-header>
<!--    <div>{{room}}</div>-->
<!--    <div>-->
<!--      Object types: {{filteredObjectTypes}}-->

<!--    </div>-->
    <!--  Выбор объекта  -->
    <a-modal v-model:open="modals.selectType.visible" title="Выберите тип объекта">
      <div class="flex flex-col gap-2 h-[50vh] pb-5 overflow-auto p-3">
        <div class="flex w-full gap-[10px]">
          <a-select
            placeholder="Тип объекта"
            v-model:value="filters.type"
            class="flex-grow"
            allow-clear
          >
            <a-select-option v-for="type in objectTypeTypes" :value="type">{{ type }}</a-select-option>
          </a-select>
          <a-select
            placeholder="Тип комнаты"
            v-model:value="filters.room"
            class="flex-grow"
            allow-clear
          >
            <a-select-option v-for="room in rooms" :value="room.id">{{ room.attributes.name }}</a-select-option>
          </a-select>
        </div>
<!--        <div>Selected: {{modals.selectType.object}}</div>-->
<!--        <div>Selected: {{selectedType}}</div>-->
        <div v-for="objectType in filteredObjectTypes" class="w-full">
          <a-card :bodyStyle="{'padding': '4px'}" hoverable
                  @click="() => {modals.selectType.visible = false; modals.selectType.selected = objectType.id; filters.type = null }">

            <div class="flex items-center gap-[10px] ">
              <a-avatar :size="32"
                        :src="fetch.baseURL + objectType.attributes.image.data.attributes.url">
              </a-avatar>
              <div class="flex flex-col">
                <div>
                  {{ objectType.attributes.name }}
                </div>
                <div class="text-gray-400 text-[0.75rem]">
                  {{ objectType.attributes.type }}
                </div>
              </div>

            </div>
          </a-card>
        </div>
      </div>
      <template #footer></template>
    </a-modal>
    <a-card v-if="(rooms.length > 0) && (objectTypes.length > 0)" id="editor" class="min-h-[1024px]  flex-grow-[3] overflow-hidden">
      <a-card
        id="editor-object-popup"
        v-if="selected"
        class="editor-object-details absolute w-[500px]"
        :style="{
          left: `${selected.left}px`,
          top: `${selected.top}px`,
          zIndex: 9999,
        }">

        <div class="flex justify-between items-center">
          <div><b>{{ objectTypeById[selected.type].attributes.name }}</b></div>

          <a-button @click="selected=null; filters.type = null">Закрыть</a-button>

        </div>
        <hr class="my-3">
        <div v-html="marked.parse(objectTypeById[selected.type].attributes.content || '')"></div>
        <div class="mt-3">
          <a-button type="primary"
                    @click="changeObject">
            Изменить
          </a-button>
          <a-button class="ml-3" type="primary" danger @click="removeObject(selected)">Удалить</a-button>
        </div>
      </a-card>
      <!--
 left: `${objectTypeById[object.type].attributes.left ? objectTypeById[object.type].attributes.left : object.left}px`,
          top: `${objectTypeById[object.type].attributes.top ? objectTypeById[object.type].attributes.top : object.top}px`,
-->
      <div

        v-for="object in objects"
        class="editor-object border-0 border-[black] absolute select-none cursor-move "
        :style="{
          left: objectTypeById[object.type].attributes.left ? objectTypeById[object.type].attributes.left :`${object.left}px`,
          top: objectTypeById[object.type].attributes.top ? objectTypeById[object.type].attributes.top :`${object.top}px`,
          width: objectTypeById[object.type].attributes.width,
          minWidth: objectTypeById[object.type].attributes.width,
          maxWidth: objectTypeById[object.type].attributes.width,
          height: objectTypeById[object.type].attributes.height,
          minHeight: objectTypeById[object.type].attributes.height,
          maxHeight: objectTypeById[object.type].attributes.height,
          zIndex: objectTypeById[object.type].attributes.is_background ? 0 : object.z,
          }"
        @mousedown="mouseDownHandler($event, object)"
        draggable="false"
        @contextmenu.stop.prevent="selected=object"
      >
        <img
          class="w-full h-full select-none"
          draggable="false"
          :src="fetch.baseURL + objectTypeById[object.type].attributes.image.data.attributes.url"

        >
      </div>
    </a-card>
    <a-card v-if="(rooms.length > 0) && (objectTypes.length > 0)" class="flex-grow-[1.5] min-h-[300px] mb-5 max-h-[300px] overflow-hidden">
      <div class="flex justify-between ">
        <div class="w-[68%] ">
          <div class="mb-1">Объекты</div>
          <div class="flex  flex-wrap gap-2 max-h-[250px] p-2 pb-5 overflow-auto items-start">
            <div v-for="object in objects" class="w-[calc(25%-8px)]">
              <a-card :bodyStyle="{'padding': '4px'}" hoverable
                      @click="() => {selected = object; modals.selectType.visible = true; modals.selectType.object = selected; selected = null;}">
                <div class="flex items-center gap-[10px] ">
                  <a-avatar :size="32"
                            :src="fetch.baseURL + objectTypeById[object.type].attributes.image.data.attributes.url">
                  </a-avatar>
                  <div class="flex flex-col">
                    <div class="flex">
                      <div>{{ objectTypeById[object.type].attributes.name }}</div>
                      <!--                      <a-button class="ml-2" size="small" type="primary">Удалить</a-button>-->
                    </div>
                    <div class="text-gray-400 text-[0.75rem]">
                      {{ objectTypeById[object.type].attributes.type }}
                    </div>
                  </div>

                </div>
              </a-card>
            </div>
            <div class="w-[23%]">
              <a-card v-if="objects.length < 24" :bodyStyle="{'padding': '4px'}" hoverable
                      style="border-style: dashed; border-color: gray"
                      @click="addNewObject(13)">
                <div class="flex items-center gap-[10px] ">
                  <a-avatar :size="32" class="flex justify-center items-center">
                    <template #icon>
                      <PlusOutlined/>
                    </template>

                  </a-avatar>
                  <div class="flex flex-col">
                    <div>
                      Добавить
                    </div>
                    <div class="text-gray-400 text-[0.75rem]">
                      Новый объект
                    </div>
                  </div>

                </div>
              </a-card>
              <a-alert v-else message="Достигнуто ограничение в 24 объектов" type="warning"/>

            </div>
          </div>


        </div>
        <div class="w-[30%]">
          <div class="mb-1">Сводка</div>
          <div class="max-h-[250px] overflow-auto">
            <div v-for="object in objects" class="flex flex-col">
              <div>
                {{ objectTypeById[object.type].attributes.name }}
              </div>
              <div class="text-gray-400 text-[0.75rem]"
                   v-html="marked.parse(objectTypeById[object.type].attributes.content || 'Нет описания')">
              </div>
            </div>
          </div>
        </div>
      </div>


    </a-card>
    <div class="mt-5"></div>
<!--    <a-card class="">-->
<!--      <a-button type="primary" @click="exportToZip">Сохранить</a-button>-->
<!--    </a-card>-->
  </div>
</template>

<script setup>
import {computed, nextTick, onBeforeMount, onMounted, reactive, ref, watch} from "vue";
import {useRouter} from 'vue-router'
import {useFetch} from "@/composables/useFetch";
import {PlusOutlined} from "@ant-design/icons-vue";

import markdownit from 'markdown-it'

const md = markdownit()

import {marked} from 'marked'
import JSZip from "jszip";
import html2canvas from "html2canvas";

const router = useRouter()
const fetch = useFetch()

const objects = ref([])
const selected = ref(null)
const er = ref(null)

const rooms = ref([])
const room = ref(null)

watch(() => room.value, (id) => {
  for (const room of rooms.value) {
    if (room.id === id) {
      objects.value = [...room.attributes.scene]
      filters.room = room.id
    }
  }
})


const modals = reactive({
  selectType: {
    visible: false,
    selected: null,
    object: null, // Текущий выбранный объект (опционально)
  }
})



const filters = reactive({
  type: null,
  room: null,
})

const selectedType = computed(() => {
  if (modals.selectType.object) {
    const typeId = modals.selectType.object.type
    const type = objectTypeById.value[typeId].attributes.type
    if (type) {
      filters.type = type
    }
    return type
  }
  filters.type = null
  return null
})

const filteredObjectTypes = computed(() => {
  let items = objectTypes.value
  if (filters.type) {
    items = items.filter(item => item.attributes.type === filters.type)
  }
  return items
})



const changeObject = () => {
  modals.selectType.visible = true;
  modals.selectType.object = selected.value;
  selected.value = null;
  selectedType.value
}

function adjustDivPosition(containerId, targetId) {
  // Получаем элементы по ID
  const container = document.getElementById(containerId);
  const target = document.getElementById(targetId);

  // Получаем размеры контейнера и целевого дива
  const containerRect = container.getBoundingClientRect();
  const targetRect = target.getBoundingClientRect();

  // Получаем текущие координаты целевого дива
  let targetX = targetRect.left - containerRect.left; // x позиция относительно контейнера
  let targetY = targetRect.top - containerRect.top;   // y позиция относительно контейнера

  // Проверяем и корректируем позицию по оси X
  if (targetX < 0) {
    targetX = 0; // Сдвигаем влево, если вышел за левую границу
  } else if (targetX + targetRect.width > containerRect.width) {
    targetX = containerRect.width - targetRect.width; // Сдвигаем вправо, если вышел за правую границу
  }

  // Проверяем и корректируем позицию по оси Y
  if (targetY < 0) {
    targetY = 0; // Сдвигаем вверх, если вышел за верхнюю границу
  } else if (targetY + targetRect.height > containerRect.height) {
    targetY = containerRect.height - targetRect.height; // Сдвигаем вниз, если вышел за нижнюю границу
  }

  // Устанавливаем скорректированные координаты целевого дива
  target.style.position = 'absolute'; // Убедитесь, что целевой див имеет позиционирование
  target.style.left = targetX + 'px';
  target.style.top = targetY + 'px';
}

watch(() => selected.value, (value) => {
  if (value) {
    nextTick(() => {
      adjustDivPosition('editor', 'editor-object-popup')

    })
  }
})

watch(() => modals.selectType.selected, (type) => {
  if (type) {
    if (modals.selectType.object) {
      modals.selectType.object.type = type
    } else {
      objects.value.push({
        type: type,
        left: 100,
        top: 100,
        z: 1,
      })
    }
  }
})
const STATES = {
  DEFAULT: null,
  OBJECT_POINTER_DOWN: 'OBJECT_POINTER_DOWN',
  OBJECT_MOVING: 'OBJECT_MOVING',
}
const state = ref(STATES.DEFAULT)

const editorState = reactive({
  object: {
    item: null,
    startLeft: 0,
    startTop: 0,
  },
  editorStartLeft: 0,
  editorStartTop: 0,
})


const getMaxObjectZIndex = () => {
  let maxZ = 0
  for (const object of objects.value) {
    if (object.z > maxZ) {
      maxZ = object.z
    }
  }
  return maxZ
}


const objectTypes = ref([])

const objectTypeTypes = computed(() => {
  const types = []
  for (const object of objectTypes.value) {
    if (!types.includes(object.attributes.type)) {
      types.push(object.attributes.type)
    }
  }
  return types
})

const objectTypeById = computed(() => {
  const result = {}
  for (const objectType of objectTypes.value) {
    result[objectType.id] = objectType
  }
  return result
})

const removeObject = (object) => {
  const idx = objects.value.indexOf(object)
  objects.value.splice(idx, 1)
  selected.value = null
  filters.type = null
}

const CODE = '69SMi3WLjP88'
onBeforeMount(async () => {
  const code = localStorage.getItem('passcode')
  if (code != CODE) {
    router.push('/login')
  }

  fetch.request('api/rooms').then((data) => {
    rooms.value = data.data
    if (rooms.value.length > 0){
      room.value = rooms.value[0].id
    }
    // objects.value.push(...[
    //   {"type": 22, "left": 1082, "top": 231, "z": 5}, {"type": 3, "left": -1, "top": 0, "z": 2}, {
    //     "type": 5,
    //     "left": -2,
    //     "top": 272,
    //     "z": 9
    //   }, {"type": 28, "left": 729, "top": 0, "z": 6}, {"type": 20, "left": 163, "top": 184, "z": 11}, {
    //     "type": 13,
    //     "left": 68,
    //     "top": 194,
    //     "z": 8
    //   }, {"type": 26, "left": 1292, "top": 196, "z": 12}
    // ])
  })

  fetch.request('api/object-types', {
    query: {
      'populate[image][fields][0]': 'name',
      'populate[image][fields][1]': 'url',
      'pagination[pageSize]	': 100,
    }
  }).then((data) => {
    objectTypes.value = data.data
    objects.value = [
      {"type": 22, "left": 1082, "top": 231, "z": 5}, {"type": 3, "left": -1, "top": 0, "z": 2}, {
        "type": 5,
        "left": -2,
        "top": 272,
        "z": 9
      }, {"type": 28, "left": 729, "top": 0, "z": 6}, {"type": 20, "left": 163, "top": 184, "z": 11}, {
        "type": 13,
        "left": 68,
        "top": 194,
        "z": 8
      }, {"type": 26, "left": 1292, "top": 196, "z": 12}
    ]
  })


})
// http://localhost:1337/api/object-types?populate[image][fields][0]=name&populate[image][fields][1]=url

const mouseDownHandler = (event, object) => {
  console.log('DOWN: ', event, state.value)
  if (state.value === STATES.DEFAULT) {
    if (event.button === 0) {
      editorState.object.item = object

      editorState.object.startLeft = object.left
      editorState.object.startTop = object.top

      editorState.editorStartLeft = event.clientX
      editorState.editorStartTop = event.clientY

      window.addEventListener('mouseup', mouseUpHandler)
      window.addEventListener('mousemove', mouseMoveHandler)

      object.z = getMaxObjectZIndex() + 1

      state.value = STATES.OBJECT_POINTER_DOWN
    }


  }

}

const mouseMoveHandler = (event) => {
  console.log('MOVE: ', state.value)

  if (state.value === STATES.OBJECT_POINTER_DOWN) {
    state.value = STATES.OBJECT_MOVING
  }

  if (state.value === STATES.OBJECT_MOVING) {
    const deltaX = event.clientX - editorState.editorStartLeft
    const deltaY = event.clientY - editorState.editorStartTop

    editorState.object.item.left = editorState.object.startLeft + deltaX
    editorState.object.item.top = editorState.object.startTop + deltaY

  }
}

const mouseUpHandler = (event) => {
  console.log('UP: ', state.value)

  if ([STATES.OBJECT_MOVING, STATES.OBJECT_POINTER_DOWN].includes(state.value)) {
    window.removeEventListener('mouseup', mouseUpHandler)
    window.removeEventListener('mousemove', mouseMoveHandler)
    state.value = STATES.DEFAULT
    localStorage.setItem('scene', JSON.stringify(objects.value))
  }

}

const addNewObject = (type) => {
  modals.selectType.visible = true
  modals.selectType.object = null
  modals.selectType.selected = null

}
import * as htmlToImage from 'html-to-image';

function loadImages() {
  const images = document.querySelectorAll('#editor img');
  const promises = [];
  console.log('IMAGES: ', images)
  images.forEach(img => {
    if (img.complete) {
      // Если изображение уже загружено, добавить пустое обещание
      promises.push(Promise.resolve());
    } else {
      // Иначе ждём загрузки изображения
      promises.push(new Promise((resolve) => {

        img.onload = resolve;
        img.onerror = resolve; // В случае ошибки также разрешаем промис
      }));
    }
  });

  return Promise.all(promises);
}

function download(dataUrl, filename) {
  // Создание временного элемента <a>
  const a = document.createElement('a');

  // Установка атрибута download для элемента <a>
  a.href = dataUrl;
  a.download = filename;

  // Добавление элемента <a> в документ
  document.body.appendChild(a);

  // Имитируем клик по элементу <a>
  a.click();

  // Удаление элемента <a> из документа
  document.body.removeChild(a);
}

// Функция для преобразования Markdown в обычный текст
function markdownToPlainText(markdown) {
  // Преобразуем Markdown в HTML
  const html = marked(markdown);

  // Создаем временный элемент для извлечения текста
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;

  // Извлекаем и возвращаем текст
  return tempElement.innerText || tempElement.textContent;
}

const exportToZip = () => {
  htmlToImage.toPng(document.getElementById('editor'))
    .then(function (dataUrl) {
      // Создаем новый ZIP-архив
      const zip = new JSZip();

      // Добавляем изображение в ZIP-архив
      // dataUrl содержит данные изображения в формате base64, сначала удалим префикс
      const base64Data = dataUrl.split(',')[1];
      zip.file('image.png', base64Data, {base64: true});
      let summary = ''
      summary += `Всего объектов: ${objects.value.length}\n\n\n`

      for (const object of objects.value) {
        const objectType = objectTypeById.value[object.type]
        console.log('OBJECT: ', object, objectType)
        summary += `${objectType.attributes.name}\n${markdownToPlainText(objectType.attributes.content || '')}\n\n`
      }
      zip.file("summary.txt", summary);
      zip.file("scene.json", JSON.stringify(objects.value));
      // Создаем ZIP-архив
      zip.generateAsync({type: 'blob'})
        .then(function (content) {
          // Загружаем ZIP-архив
          const link = document.createElement('a');
          link.href = URL.createObjectURL(content);
          link.download = 'out.zip';
          link.click();
        });
    })
    .catch(function (error) {
      console.error('Ошибка:', error);
    });
}

</script>

<style scoped>
.editor-object {

}

.editor-object > img {
  filter: none;
}

.editor-object:hover > img {
  filter: drop-shadow(0px 0px 10px #0000ff);
}

.editor-object-details >>> a {
  color: #6969ff;
  text-decoration: underline;
}
</style>